<template>
    <div class="container">
        <div class="zz" v-if="loading"></div>
        <top-layer></top-layer>
        <div class="content">
            <center-layer></center-layer>
            <div v-if="false" class="main">
                <div class="main-title">千万货源&nbsp;多跑多赚</div>
                <div class="main-input">
                    <el-input
                        placeholder="请输入手机号码"
                        prefix-icon="el-icon-search"
                        v-model="phone">
                    </el-input>
                </div>
                <div class="main-input">
                    <el-input
                        placeholder="请输入验证码"
                        prefix-icon="el-icon-search"
                        v-model="code">
                    </el-input>
                    <div class="main-code" @click="getCode()">获取验证码</div>
                </div>
                <div class="main-join" @click="otherPage">立即加入</div>
                <div class="main-check">
                    <el-checkbox v-model="checked">已阅读并同意《第三方合作清单》《用户服务协议》《隐私政策》《用户授权协议》</el-checkbox>
                </div>
            </div>
            <div v-if="false" class="code">
                <div class="code-title">
                    安全验证
                </div>
                <div class="code-tool">
                    请拖动图片下方的滑块，按提示完成安全验证
                </div>
                <slide-verify
                ref="slideblock"
                @again="onAgain"
                @fulfilled="onFulfilled"
                @success="onSuccess"
                @fail="onFail"
                @refresh="onRefresh"
                :accuracy="accuracy"
                :slider-text="text"
                ></slide-verify>
            </div>
            <div v-if="false" class="driver-app">
                <div class="code-title">账号已存在，快去小程序体验吧</div>
                <div class="driver-img">
                    <div class="image"></div>
                    <div class="image-bottom">
                        微信扫一扫，体验小程序
                    </div>
                </div>
                <div @click="goHome()">返回首页</div>
            </div>
            <div class="main" style="justify-content:center;">
                <div class="main-title" style="text-align:center">千万货源&nbsp;多跑多赚</div>
                <div style="padding:0 50px 0 50px;margin-top:20px;">
                    <img src="../image/driver-mp-code.jpg" style="width:100%;height:auto">
                </div>
            </div>
        </div>
        <bottom-layer></bottom-layer>
    </div>
</template>
<script>
import topLayer from '../components/top.vue'
import bottomLayer from '../components/bottom.vue'
import centerLayer from '../components/center.vue'
export default {
    data() {
        return {
            phone: '',
            code: '',
            checked: true,
            show: false,
            text: '向右拖动滑块填充拼图',
            // 精确度小，可允许的误差范围小；为1时，则表示滑块要与凹槽完全重叠，才能验证成功。默认值为5
            accuracy: 1,
            loading: false,
            driverShow: false
        }
    },
    components: {
        topLayer,
        bottomLayer,
        centerLayer
    },

    methods: {
        otherPage() {
            // this.$router.push('/shipper/join')
            this.driverShow = true
        },
        getCode() {
            this.loading = true
        },
        goHome() {
            this.$router.push('/index')
        },
        onSuccess(){
            console.log('验证通过');
            this.loading = false
        },
        onFail(){
            console.log('验证不通过');
        },
        onRefresh(){
            console.log('点击了刷新小图标');
        },
        onFulfilled() {
            console.log('刷新成功啦！');
        },
        onAgain() {
            console.log('检测到非人为操作的哦！');
            // 刷新
            this.$refs.slideblock.reset();
        },
        handleClick() {
            // 父组件直接可以调用刷新方法
            this.$refs.slideblock.reset();
        },
    }
}
</script>
<style lang="css" scoped>
.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.zz {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .3);
    z-index: 99;
}
.content {
    width: 100%;
    height: calc(100vh - 250px);
    background: url(../image/banner.jpg) no-repeat;
    background-size: 100% 100%;
    position: relative;
    min-height: 700px;
}
.main {
    position: absolute;
    top: 150px;
    right: 15%;
    background: white;
    height: 400px;
    width: 400px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 30px 30px;
}
/* .main div {
    height: 50px;
} */
.main .main-title {
    height: 50px;
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    line-height: 50px;
}
.main .main-input {
    height: 50px;
    /* display: flex; */
    position: relative;
}
.main .main-code {
    height: 100%;
    width: 150px;
    color: rgba(3, 93, 252, 1);
    position: absolute;
    right: 0;
    top: 0;
    line-height: 50px;
    cursor: pointer;
}
.main .main-join {
    height: 50px;
    width: 100%;
    background: rgba(3, 93, 252, 1);
    line-height: 50px;
    border-radius: 25px;
    color: white;
    cursor: pointer;
}
.main .main-check {
    text-align: left;
    height: 50px;
}
.code, .driver-app {
    width: 400px;
    height: 400px;
    position: absolute;
    top: 150px;
    left: calc(50% - 200px);
    box-sizing: border-box;
    padding: 30px;
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 999;
}
.code-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}
.code .code-tool {
    box-sizing: border-box;
    padding: 0 20px;
    margin-bottom: 10px;
}
.driver-app div:nth-child(3){
    width: 80%;
    height: 40px;
    border-radius: 20px;
    border: 1px solid rgba(3, 93, 252, 1);
    color: rgb(3, 93, 252);
    line-height: 40px;
    cursor: pointer;
}
.driver-img {
    height: 300px;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.driver-img .image {
    height: 160px;
    width: 160px;
    border: 1px solid;
    background: url(../image/code.png) no-repeat;
    background-size: 100% 100%;
}
.driver-img .image-bottom {
    font-size: 16px;
    font-weight: bold;
}
</style>
<style>
.el-input .el-input__inner {
    border-radius: 25px !important;
    background: rgb(246, 247, 249);
    height: 50px;
    font-size: 16px;
    font-weight: bold;
}
.el-checkbox__input {
    vertical-align: top;
}
.el-checkbox__label {
    white-space: normal;
}
.el-checkbox__inner {
    border-radius: 50%;
}
</style>
